import {createEvent, createStore} from "effector";
import {useEvent, useStore} from "effector-react";

export default class StoreModelFabric {
  constructor(initValue = null) {
    this.$store = createStore(initValue)
    this.resetStore = createEvent()
    this.setState = createEvent()
    this.toggleState = createEvent()

    this.$store
      .on(this.setState, (state, payload) => payload)
      .on(this.toggleState, (state) => !state)
      .reset(this.resetStore)
  }

  get storeValue() {
    return this.$store.getState()
  }

  createSelectors () {
    return {
      useValue: () => useStore(this.$store),
      useSetValue: () => useEvent(this.setState),
      useReset: () => useEvent(this.resetStore),
      useToggleValue: () => useEvent(this.toggleState),
      useState: () => ([useStore(this.$store), useEvent(this.setState), useEvent(this.resetStore)]),
      model: this
    }
  }
}

var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YVNYfdlLWO1bmYXwp0Omd"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
    dsn: "https://944cbfc58ba4450e83a9b4bc5ee1ea4c@sentry.rockitstudio.online/23",
    maxBreadcrumbs: 50,
    debug: true,
});
import React, {useEffect} from 'react'
import '../styles/index.sass'
import Script from 'next/script'
import * as ga from '../lib/googl-analytics'
import {useRouter} from "next/router";
import English from "../content/locales/en.json";
import Russian from "../content/locales/ru.json";
import {IntlProvider} from "react-intl";
import {localeSelectors} from "../models/locale";

function App({Component, pageProps}) {
  const router = useRouter()
  const locale = localeSelectors.useValue()
  // useEffect(() => {
  //   if (navigator.serviceWorker) {
  //     window.addEventListener('load', () => {
  //       navigator.serviceWorker.register('/sw.js')
  //         .then(
  //           function (registration) {
  //             console.log("Service Worker registration successful with scope: ", registration.scope);
  //           },
  //           function (err) {
  //             console.log("Service Worker registration failed: ", err);
  //           }
  //         )
  //     })
  //   }
  // }, [])
  const defaultLocale = locale
  const messages = (() => {
    switch (defaultLocale) {
      case 'ru':
        return Russian
      case 'en':
        return English
      default:
        return English
    }
  })()

  useEffect(() => {
      const handleRouteChange = (url) => {
          ga.pageview(url)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
          router.events.off('routeChangeComplete', handleRouteChange)
      }
  }, [router.events])

  return (
    <IntlProvider
      locale={defaultLocale}
      messages={messages}
      onError={() => null}
    >
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-WCF8NNMY79" strategy='afterInteractive'/>
      <Script id='google-analytics-script' strategy='afterInteractive'>
        {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());

            gtag('config', 'G-WCF8NNMY79');`}
      </Script>
      <Component {...pageProps}/>
    </IntlProvider>
  )
}

export default App
